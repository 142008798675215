import React from 'react'
import StaticPage from '../../pages-private/page/StaticPage'
import Link from '../../components/Link/GatsbyLink'
import ROUTES from '../../config/routes'

const Help = () => {
  return (
    <StaticPage>
      <h1>Help Center</h1>

      <ul>
        <li>
          <Link to={ROUTES.terms}>Terms & Conditions</Link>
        </li>

        <li>
          <Link to={ROUTES.cookies}>Cookie Policy</Link>
        </li>

        <li>
          <Link to={ROUTES.privacy}>Privacy Policy</Link>
        </li>

        <li>
          <Link to={ROUTES.ourStory}>Our Story</Link>
        </li>
      </ul>
    </StaticPage>
  )
}

export default Help
