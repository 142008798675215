import React from 'react'
import PropTypes from 'prop-types'
import { NakedLayout } from '../../components/Layout'
import NavBar from '../../components/Navbar'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
import Footer from '../../components/Footer'
import ROUTES from '../../config/routes'
import { Code } from 'react-content-loader'

const StaticPage = ({ children, title, description, isLoading, content }) => {
  const { t } = useTranslation('pages')

  const proseFullWidth = false

  const classNames = classnames('prose dark:prose-dark mx-auto', {
    'prose-sm sm:prose-sm': true,
    'md:prose': true,
    'lg:prose-lg': true,
    'xl:prose-xl': true,
    '2xl:prose-2xl': true,
    'w-full max-w-none md:max-w-none': proseFullWidth,
    hidden: isLoading === true,
    block: isLoading === false,
  })

  const contentElement = content ? (
    <div className={classNames} dangerouslySetInnerHTML={{ __html: content }} />
  ) : (
    <div className={classNames}>{children}</div>
  )

  return (
    <NakedLayout isHeightFull>
      <NavBar
        navChildClassnames={'max-w-7xl'}
        menuClassnames={'h-28'}
        leftItems={[
          {
            label: t('page.navbarLinks.helpCenter'),
            href: ROUTES.help,
          },
        ]}
      />

      <Helmet>
        <title>{title}</title>
        <description>{description}</description>
      </Helmet>

      <div className={'p-10 sm:py-12 md:py-16 lg:py-20 px-6'}>
        {isLoading ? (
          <div className="max-w-3xl mx-auto">
            <Code className="mb-12" />
            <Code />
          </div>
        ) : (
          contentElement
        )}
      </div>

      <Footer className={'max-w-7xl px-2 border-t border-gray-100'} />
    </NakedLayout>
  )
}

StaticPage.propTypes = {
  children: PropTypes.node,
  content: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  isLoading: PropTypes.bool,
}

export default StaticPage
